import React, { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { Aside } from "../components/Aside";

type DefaultLayoutProps = {
    children: ReactNode;
};

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
    return (
        <div className="d-flex flex-column flex-root" id="kt_app_root">
            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                    <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                        <div className="w-100" style={{ maxWidth: '500px' }}>
                            {children}
                        </div>
                    </div>
                </div>
                <Aside />
            </div>
        </div>
    );
};


import React, { FunctionComponent, ReactNode } from 'react';

interface CardProps {
    title: string; // El título de la tarjeta
    children: ReactNode; // Los elementos hijos que Card va a envolver
    isNewButton?: boolean; // Opcional: si se muestra un botón de "Nuevo"
    isOrderButton?: boolean; // Opcional: si se muestra un botón de "Ordenar"
    actionNew?: () => void; // Opcional: función a ejecutar cuando se hace clic en Nuevo
    actionOrder?: () => void; // Opcional: función a ejecutar cuando se hace clic en Ordenar
    toolActions?: () => ReactNode; // Opcional: una función que renderiza nodos adicionales de React, como botones o enlaces
}

export const Card: FunctionComponent<CardProps> = ({
                                                       title,
                                                       children,
                                                       isNewButton = false,
                                                       isOrderButton = false,
                                                       actionNew,
                                                       actionOrder,
                                                       toolActions,
                                                   }) => {
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">{title}</h3>
                {toolActions && <div className="card-toolbar">{toolActions()}</div>}
            </div>
            <div className="card-body">{children}</div>

        </div>
    );
};

export default Card;

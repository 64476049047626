import {PrivateLayout} from "../layouts/PrivateLayout";
import {Card} from "../components/ui/Card";
export const Setting = () => {
    return (
        <PrivateLayout>
            <div className="container mt-5">
                <Card title={'Perfil'}>

                </Card>
            </div>
        </PrivateLayout>
    )
}
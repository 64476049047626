// @ts-nocheck
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export interface IFormInput {
    tarifa_id?: number;
    tarifa_corte: number;
    tarifa_reposicion: number;
    cargo_fijo: number;
    valor: number;
    mes: number;
    year: number;
}

interface FormularioProps {
    isEdit: boolean;
    onSubmit: (data: IFormInput) => void;
    errors: any;
}

const Formulario: React.FC<FormularioProps> = ({
                                                   isEdit,
                                                   onSubmit,
                                                   errors
                                               }) => {
    const { register, setValue } = useFormContext();

    useEffect(() => {
        if (!isEdit) {
            setValue("tarifa_corte", "");
            setValue("tarifa_reposicion", "");
            setValue("cargo_fijo", "");
            setValue("valor", "");
            setValue("mes", "");
            setValue("year", "");
        }
    }, [isEdit, setValue]);

    return (
        <form onSubmit={onSubmit}>
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Tarifa Corte</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("tarifa_corte", { required: true })}
                />

                <input
                    type="hidden"
                    {...register('tipo_tarifa')}
                    value='consumo'
                />

                {errors.tarifa_corte && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Tarifa Reposición</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("tarifa_reposicion", { required: true })}
                />
                {errors.tarifa_reposicion && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Cargo Fijo</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("cargo_fijo", { required: true })}
                />
                {errors.cargo_fijo && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Valor</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("valor", { required: true })}
                />
                {errors.valor && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Mes</label>
                <select
                    className="form-select form-select-solid"
                    {...register("mes", { required: true })}
                >
                    <option value="">Selecciona un mes</option>
                    <option value="1">Enero</option>
                    <option value="2">Febrero</option>
                    <option value="3">Marzo</option>
                    <option value="4">Abril</option>
                    <option value="5">Mayo</option>
                    <option value="6">Junio</option>
                    <option value="7">Julio</option>
                    <option value="8">Agosto</option>
                    <option value="9">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                </select>
                {errors.mes && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Año</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("year", { required: true })}
                />
                {errors.year && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">Guardar</button>
            </div>
        </form>
    );
};

export default Formulario;

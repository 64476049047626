import React from 'react';

interface PaginationProps {
    links: Array<{ url: string | null, label: string, active: boolean }>;
    onChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({ links, onChange }) => {
    // Asegurarse de que la página por defecto sea la 1 si no hay ningún link activo
    const defaultActiveIndex = links.findIndex(link => link.active) !== -1 ? links.findIndex(link => link.active) : 0;

    return (
        <div className="dataTables_paginate paging_simple_numbers">
            <ul className="pagination">
                {/* Asegurarse de usar una función de callback para manejar el cambio de página */}
                <li className={`paginate_button page-item ${defaultActiveIndex === 0 ? 'disabled' : ''}`}
                    onClick={() => defaultActiveIndex !== 0 && onChange(1)}>
                    <a href="#" className="page-link"><i className="previous"></i></a>
                </li>
                {links.slice(1, links.length - 1).map((link, index) => (
                    <li key={index}
                        className={`paginate_button page-item ${link.active ? 'active' : ''}`}
                        onClick={() => !link.active && onChange(index + 1)}>
                        <a href="#" className="page-link">{link.label}</a>
                    </li>
                ))}
                <li className={`paginate_button page-item ${defaultActiveIndex === links.length - 1 ? 'disabled' : ''}`}
                    onClick={() => defaultActiveIndex !== links.length - 1 && onChange(links.length - 1)}>
                    <a href="#" className="page-link"><i className="next"></i></a>
                </li>
            </ul>
        </div>
    );
};

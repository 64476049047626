import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL_BASE } from "../config/config";

export interface LoginState {
    user: any;
    token: string | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: LoginState = {
    user: null,
    token: null,
    isLoading: false,
    error: null,
};

export const loginUser = createAsyncThunk(
    'login/loginUser',
    async (credentials: { email: string; password: string }) => {
        const response = await axios.post(`${URL_BASE}/login`, credentials);
        return response.data;
    }
);

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload.user;
                state.token = action.payload.token;
                state.error = null;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to login';
            });
    },
});

export const { logout } = loginSlice.actions;

export default loginSlice.reducer;



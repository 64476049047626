// @ts-nocheck
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { DataTable } from "../../components/ui/DataTable";
import { columnsPagos } from "../../data/Datos";

interface TableProps {
    pagos: Array<any>;
}

const Table: React.FC<TableProps> = ({ pagos }) => {
    const handleDownloadPdf = (id: number) => {
        // Lógica para descargar PDF
    };

    const handleSendWhatsapp = (id: number) => {
        // Lógica para enviar por WhatsApp
    };

    return (
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
                {pagos?.length ? (
                    <DataTable
                        items={pagos}
                        columns={columnsPagos}
                        idKey={'pago_id'}
                        isActions={true}
                        renderActions={(id) => (
                            <div className='btn-group'>
                                <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => handleDownloadPdf(id)}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} />
                                </button>
                                <button
                                    className="btn btn-sm btn-success"
                                    onClick={() => handleSendWhatsapp(id)}
                                >
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </button>
                            </div>
                        )}
                    />
                ) : (
                    <p style={{ marginTop: '30px', fontSize: '16px' }}>No hay pagos disponibles para este mes y año.</p>
                )}
            </div>
        </div>
    );
};

export default Table;

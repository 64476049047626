// @ts-nocheck
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faExclamationCircle, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import '../../assets/css/ResumenMes.css';

const ResumenMes = ({ resumen }) => {
    return (
        <div className="resumen-container">

            <div className="card-resumen-container">
                <div className="card-resumen">
                    <FontAwesomeIcon icon={faDollarSign} size="3x" className="icon icon-recaudado"/>
                    <h6>Total Recaudado</h6>
                    <p>${resumen.recaudado}</p>
                </div>
                <div className="card-resumen">
                    <FontAwesomeIcon icon={faExclamationCircle} size="3x" className="icon icon-pendiente"/>
                    <h6>Total Pendiente</h6>
                    <p>${resumen.pendiente}</p>
                </div>
                <div className="card-resumen">
                    <FontAwesomeIcon icon={faCheckCircle} size="3x" className="icon icon-pagado"/>
                    <h6>Boletas Pagadas</h6>
                    <p>{resumen.pagadas}</p>
                </div>
                <div className="card-resumen">
                    <FontAwesomeIcon icon={faTimesCircle} size="3x" className="icon icon-vencido"/>
                    <h6>Boletas Vencidas</h6>
                    <p>{resumen.vencidas}</p>
                </div>
            </div>
        </div>
    );
};

export default ResumenMes;


import {logout} from "../slice/LoginSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store/store";
import {DefaultLayout} from "../layouts/DefaultLayout";
import PrivateRoute from "../routes/PrivateRoute";
import {PrivateLayout} from "../layouts/PrivateLayout";
import {Card} from "react-bootstrap";
import Swal from 'sweetalert2';
import {useCRUD} from "../hooks/useCRUD";

export const Dashboard = () => {
    const user = useSelector((state: any) => state.login.user)
    const dispatch = useDispatch();



    return (
        <PrivateLayout>
            <div className="container mt-5">

                <Card title="Inicio">
                    <div className="py-5 px-5">
                        <h1>Bienvenido, {user.name}</h1>
                    </div>
                </Card>

            </div>

           {/* <div className="container mt-5">
                <div className='row'>
                    <div className="col-md-6">
                        <Card title="">
                            <div className="py-5 px-5">
                                <MonthlySalesBarChart/>
                            </div>
                        </Card>
                    </div>
                    <div className="col-md-6">
                        <Card title="">
                            <div className="py-5 px-5">
                                <DailySalesBarChart/>
                            </div>
                        </Card>
                    </div>
                </div>


            </div>*/}

        </PrivateLayout>
    )
}
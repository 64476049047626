// @ts-nocheck
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "../../components/ui/DataTable";
import { columnsmonthlyConsumption } from "../../data/Datos";

interface TableProps {
    consumos: Array<any>;
    newConsumo: (id: string | number) => void;
}

const Table: React.FC<TableProps> = ({ consumos, newConsumo }) => {
    return (
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
                {consumos?.length ? (
                    <DataTable
                        items={consumos}
                        columns={columnsmonthlyConsumption}
                        idKey={'lectura_id'}
                        isActions={true}
                        renderActions={(id) => (
                            <div className='btn-group'>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() => newConsumo(Number(id))} // Convertir a número
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </button>
                            </div>
                        )}
                    />
                ) : (
                    <p style={{ marginTop: '30px', fontSize: '16px' }}>No hay consumos disponibles para este mes y año.</p>
                )}
            </div>
        </div>
    );
};

export default Table;

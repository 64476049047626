// @ts-nocheck
import { Outlet, Routes, Route } from 'react-router-dom';
import Login from '../pages/Login';
import PrivateRoute from './PrivateRoute';
import React from "react"; // Vamos a crear este componente a continuación.
import {Dashboard} from "../pages/Dashboard";
import {Usuarios} from "../pages/Usuarios";
import {Clientes} from "../pages/Clientes";
import {ConsumosLuz} from "../pages/ConsumosLuz";
import {Perfil} from "../pages/Perfil";
import {Setting} from "../pages/Setting";
import {Tarifas} from "../pages/Tarifas";
import {Facturas} from "../pages/Facturas";
import Pagos from "../pages/Pagos";
// Componentes de ejemplo

const NotFound = () => <div>404 Page Not Found</div>;

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/clientes" element={<PrivateRoute><Clientes /></PrivateRoute>} />
            <Route path="/consumo-mensual-luz" element={<PrivateRoute><ConsumosLuz /></PrivateRoute>} />
            <Route path="/tarifas-mensual" element={<PrivateRoute><Tarifas /></PrivateRoute>} />
            <Route path="/boleta-mensual-luz" element={<PrivateRoute><Facturas /></PrivateRoute>} />
            <Route path="/pago-mensual-luz" element={<PrivateRoute><Pagos /></PrivateRoute>} />
            <Route path="/usuarios" element={<PrivateRoute><Usuarios /></PrivateRoute>} />
            <Route path="/perfil-usuario" element={<PrivateRoute><Perfil /></PrivateRoute>} />
            <Route path="/configuracion-sistema" element={<PrivateRoute><Setting /></PrivateRoute>} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;

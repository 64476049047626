// @ts-nocheck
import React from 'react';
import Select from 'react-select';

const Filtros = ({
                     handleMonthChange,
                     handleYearChange,
                     handleFilterChange,
                     handleReset,
                     mesActual,
                     yearActual,
                     clientes,
                     selectedClient
                 }) => {
    return (
        <div className="row">
            <div className="col-md-3">
                <select
                    className="form-select form-select-solid"
                    onChange={handleMonthChange}
                    value={mesActual}
                >
                    <option value="1">Enero</option>
                    <option value="2">Febrero</option>
                    <option value="3">Marzo</option>
                    <option value="4">Abril</option>
                    <option value="5">Mayo</option>
                    <option value="6">Junio</option>
                    <option value="7">Julio</option>
                    <option value="8">Agosto</option>
                    <option value="9">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                </select>
            </div>
            <div className="col-md-3">
                <select
                    className="form-select form-select-solid"
                    onChange={handleYearChange}
                    value={yearActual}
                >
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                </select>
            </div>
            <div className="col-md-3">
                <Select
                    options={clientes.map(cliente => ({
                        value: cliente.cliente_id,
                        label: `${cliente.nombre} ${cliente.apellido}`
                    }))}
                    value={selectedClient}
                    onChange={handleFilterChange}
                />
            </div>
            <div className="col-md-3">
                <button className="btn btn-danger btn-sm" onClick={handleReset}>
                    Limpiar
                </button>
            </div>
        </div>
    );
}

export default Filtros;

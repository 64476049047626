// hooks/useCRUD.ts
import { useState, useEffect } from 'react';
import axios from 'axios';

interface CRUDOptions {
    endpoint: string;
    category?: string;
}

export const useCRUD = (options: CRUDOptions) => {
    const [data, setData] = useState([]);

    const getURLWithCategory = () => {
        return options.endpoint;
    };

    const fetchData = async () => {
        const url = getURLWithCategory();
        const { data: fetchedData, status } = await axios.get(url);
        if (status === 200) {
            setData(fetchedData);
        }
    };


    useEffect(() => {
        fetchData();// @ts-ignore
    }, [options.endpoint]);

    const deleteData = async (id: number) => {

        const { status } = await axios.delete(`${options.endpoint}/${id}`);
        if (status === 200) {
            fetchData();
        }
    };

    const addOrUpdateData = async (formData: FormData) => {
        const url = getURLWithCategory();
        const { status } = await axios.post(url, formData);
        if (status === 200) {
            fetchData();
        }
    };

    return {
        data,
        fetchData,
        deleteData,
        addOrUpdateData
    };
};


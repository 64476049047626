import React, {useState, FunctionComponent, ReactNode} from 'react';

type Column = {
    id: number;
    label: string;
    key?: string;
    isImage?: boolean;
    urlImage?: string;
    shouldHaveBadge?: boolean;
    badge?: boolean;
    isVisible?: boolean;
};

type Item = {
    id: string;
    [key: string]: any; // additional properties of Item
};

type ColorType = {
    [key: string]: string;
};

interface Props {
    columns: Column[];
    items: Item[];
    isCheck?: boolean;
    idKey?: string;
    isActions?: boolean;
    renderActions?: (id: string | number) => ReactNode; // render prop for actions
    color?: ColorType;
    loading?: boolean;
    onSortChange?: (columnKey: string, direction: string) => void; // función opcional
    visibleColumns?: { [key: string]: boolean }; // object with keys of column keys and values of boolean
}

export const DataTable: FunctionComponent<Props> = ({
                                                        columns,
                                                        items,
                                                        isCheck = false,
                                                        idKey = 'id',
                                                        isActions = false,
                                                        renderActions,
                                                        color = {},
                                                        loading = false,
                                                        onSortChange, // función opcional
                                                        visibleColumns = {}, // object with keys of column keys and values of boolean
                                                    }) => {

    const [sortAsc, setSortAsc] = useState(true);
    const [sortedColumn, setSortedColumn] = useState('');

    const [showMenu, setShowMenu] = useState(false);

    if (items === undefined) {
        items = [];
    }
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    const getNestedValue = (obj: any, path: string) => {
        return path.split('.').reduce((obj: any, key: string) => {
            if (obj && obj[key]) {
                return obj[key];
            }
            return 'Sin registro';
        }, obj);
    };

    const getColorType = (tipo: string) => {
        if (!color.hasOwnProperty(tipo)) {
            throw new Error(`Tipo ${tipo} no es válido`);
        }

        const colorClass = color[tipo];
        return <span className={`badge ${colorClass}`}>{tipo}</span>;
    };

    const handleSort = (columnKey: string) => {
        const newSortAsc = sortedColumn === columnKey ? !sortAsc : true;
        setSortAsc(newSortAsc);
        setSortedColumn(columnKey);
        // Llama a la función de recarga de datos proporciónada por las props
        onSortChange && onSortChange(columnKey, newSortAsc ? 'asc' : 'desc');
    };

    const isColumnVisible = (columnId: number) => {
        return visibleColumns.hasOwnProperty(columnId) ? visibleColumns[columnId] : true;
    };

    return (
        <div className="py-5">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">
                <thead>
                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    {isCheck && (
                        <th className="w-10px pe-2">
                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                <input className="form-check-input" type="checkbox" data-kt-check="true"
                                       data-kt-check-target="#kt_customers_table .form-check-input" value="1"/>
                            </div>
                        </th>
                    )}
                    {columns.map((column) => isColumnVisible(column.id) && (
                        <th key={column.key}
                            onClick={() => handleSort(column.key!)}
                            className={sortAsc && sortedColumn === column.key ? 'table-sort-asc' : 'table-sort-desc'}>
                            {column.label}
                        </th>
                    ))}
                    {isActions && (
                        <th className="text-center">Acciones</th>
                    )}
                </tr>
                </thead>
                <tbody className="fw-semibold text-gray-600">
                {/* Insert filtros and empty slots equivalents here */}
                {items.length === 0 && (
                    <tr>
                        <td colSpan={100} className="text-center">No hay registros</td>
                    </tr>
                )}
                {loading && (
                    <div className="table-loading-message">Cargando...</div>
                )}
                {items.map((item) => (
                    <tr className="odd" key={item.id}>
                        {isCheck && (
                            <td>
                                <div className="form-check form-check-sm form-check-custom form-check-solid">
                                    <input className="form-check-input" type="checkbox" value="1"/>
                                </div>
                            </td>
                        )}

                        {columns.map((column) => isColumnVisible(column.id) && (
                            <td key={column.key}>
                                {column.badge ? getColorType(item[column.key!]) : getNestedValue(item, column.key!)}
                            </td>
                        ))}

                        {isActions && renderActions && (
                            <td className="text-center">
                                {renderActions(item[idKey])}
                            </td>
                        )}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};




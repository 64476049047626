import React from 'react';
import { Modal as ModalBootstrap } from 'react-bootstrap';

interface ModalProps {
    children: React.ReactNode;
    show: boolean;
    handleClose?: () => void;  // Cambiado a un tipo de función
    title: string;
}

export const Modal: React.FC<ModalProps> = ({ children, show, handleClose, title }) => {

    return (
        <ModalBootstrap
            show={show}
            onHide={handleClose}  // Añadido el prop onHide
            size="lg"
            centered
            dialogClassName="modal-90w centered-modal"
            contentClassName="custom-modal-content"
        >
            <ModalBootstrap.Header closeButton>
                <ModalBootstrap.Title>{title}</ModalBootstrap.Title>
            </ModalBootstrap.Header>
            <ModalBootstrap.Body>
                {children}
            </ModalBootstrap.Body>
        </ModalBootstrap>
    );
};




// @ts-nocheck
import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Select from 'react-select';

export interface IFormInput {
    lectura_id?: number;
    client_id: number;
    direccion_id: number;
    fecha_lectura: string;
    consumo_kwh: number;
    fecha_vencimiento?: string;
    fecha_proxima_lectura?: string;
}

interface FormularioProps {
    isEdit: boolean;
    onSubmit: (data: IFormInput) => void;
    errors: any;
    clientes: Array<any>;
    direcciones: Array<any>;
    handleClientChange: (clientId: number) => void;
}

const Formulario: React.FC<FormularioProps> = ({
                                                   isEdit,
                                                   onSubmit,
                                                   errors,
                                                   clientes,
                                                   direcciones,
                                                   handleClientChange
                                               }) => {
    const { register, setValue, watch } = useFormContext();
    const selectedClientId = watch("client_id");
    const selectedDireccionId = watch("direccion_id");

    setValue("fecha_lectura", new Date().toISOString().split('T')[0]);

    useEffect(() => {
        if (!isEdit) {
            setValue("client_id", null);
            setValue("direccion_id", null);
        }
    }, [isEdit, setValue]);

    return (
        <form onSubmit={onSubmit}>
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Cliente</label>
                <Select
                    options={clientes.map(cliente => ({
                        value: cliente.cliente_id,
                        label: `${cliente.nombre} ${cliente.apellido}`
                    }))}
                    value={clientes.find(cliente => cliente.cliente_id === selectedClientId) || null}
                    onChange={(selectedOption) => {
                        setValue('client_id', selectedOption?.value);
                        handleClientChange(selectedOption?.value);
                    }}
                    isDisabled={isEdit}
                />
                {errors.client_id && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Dirección</label>
                <Select
                    options={direcciones.map(direccion => ({
                        value: direccion.direccion_id,
                        label: direccion.calle
                    }))}
                    value={direcciones.find(direccion => direccion.direccion_id === selectedDireccionId) || null}
                    onChange={(selectedOption) => setValue('direccion_id', selectedOption?.value)}
                />
                {errors.direccion_id && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Fecha de lectura</label>
                <input
                    type="date"
                    className="form-control form-control-solid"
                    {...register("fecha_lectura", { required: true })}
                    disabled={isEdit}
                    onChange={(e) => {
                        const fechaLectura = new Date(e.target.value);
                        const fechaVencimiento = new Date(fechaLectura);
                        fechaVencimiento.setDate(fechaVencimiento.getDate() + 30);
                        const fechaProximaLectura = new Date(fechaLectura);
                        fechaProximaLectura.setMonth(fechaProximaLectura.getMonth() + 1);

                        setValue('fecha_vencimiento', fechaVencimiento.toISOString().split('T')[0]);
                        setValue('fecha_proxima_lectura', fechaProximaLectura.toISOString().split('T')[0]);
                    }}
                />
                {errors.fecha_lectura && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Valor de consumo (kWh)</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("consumo_kwh", { required: true })}
                />
                {errors.consumo_kwh && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">Guardar</button>
            </div>
        </form>
    );
};

export default Formulario;







import {useEffect, useState} from "react";
import {useForm, SubmitHandler, useFieldArray} from "react-hook-form";
import axios from "axios";
import {URL_BASE} from "../config/config";
import {Card} from "../components/ui/Card";
import {Modal} from "../components/ui/Modal";
import Swal from "sweetalert2";
import {columnsClientes} from "../data/Datos";
import {useCRUD} from "../hooks/useCRUD";
import {PrivateLayout} from "../layouts/PrivateLayout";
import {DataTable} from "../components/ui/DataTable";
import {Pagination} from "../components/ui/Pagination";
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faSort, faTrash, faEdit, faDownload, faFilter, faUpload} from '@fortawesome/free-solid-svg-icons';

interface IFormInput {
    id: string;
    cliente_id?: number;
    nombre: string;
    apellido: string;
    rut: string;
    email: string;
    telefono: string;
    direcciones: {
        direccion_id?: number;
        calle: string;
        ciudad: string;
        estado: string;
        codigo_postal: string;
        numero_cliente: string;
        numero_medidor: string;
    }[];
}

export const Clientes = () => {
    const {register, handleSubmit, reset, setValue, formState: {errors}, clearErrors, control} = useForm<IFormInput>({
        defaultValues: {
            direcciones: [{calle: "", ciudad: "", estado: "", codigo_postal: "", numero_cliente:"", numero_medidor:""}]
        }
    });
    const {fields, append, remove} = useFieldArray({
        control,
        name: "direcciones"
    });
    const [clientes, setClientes] = useState<any[]>([]);
    const [houses, setHouses] = useState<any[]>([]);
    const [isModalShow, setIsModalShow] = useState(false);
    const [isImportModalShow, setIsImportModalShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [paginate, setPaginate] = useState([]);
    const [params, setParams] = useState({
        opciones: 'listPage',
        orderPor: 'cliente_id',
        order: 'asc',
        perpage: 10,
        filters: {},
        page: 1
    });
    const [showFilters, setShowFilters] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);

    const {data, fetchData, deleteData, addOrUpdateData} = useCRUD({
        endpoint: URL_BASE + '/clientes?opcion=paginate',
    });

    useEffect(() => {
        if (data) {
            // @ts-ignore
            setHouses(data.data);
            // @ts-ignore
            setPaginate(data.links)
        }
    }, [data]);

    useEffect(() => {
        // Obtener lista completa de clientes para el filtro
        axios.get(URL_BASE + '/clientes')
            .then(response => {
                setClientes(response.data);
            });
    }, []);

    const deleteHouses = (id: number) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar este cliente?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Sí, eliminar!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const {status} = await axios.delete(URL_BASE + '/clientes/' + id);
                if (status === 200) {
                    Swal.fire(
                        'Eliminado!',
                        'El cliente ha sido eliminado.',
                        'success'
                    )
                    fetchData();
                }
            }
        });
    };

    const newHouse = (id = 0) => {
        if (id > 0) {
            setIsEdit(true);

            const houseEdit = houses.find((house: any) => house.cliente_id === id);

            if (houseEdit) {
                setValue('cliente_id', houseEdit.cliente_id);
                setValue('nombre', houseEdit.nombre);
                setValue('apellido', houseEdit.apellido);
                setValue('rut', houseEdit.rut);
                setValue('email', houseEdit.email);
                setValue('telefono', houseEdit.telefono);

                // Usar reset para establecer todos los valores al mismo tiempo
                reset({
                    cliente_id: houseEdit.cliente_id,
                    nombre: houseEdit.nombre,
                    apellido: houseEdit.apellido,
                    rut: houseEdit.rut,
                    email: houseEdit.email,
                    telefono: houseEdit.telefono,
                    direcciones: houseEdit.direcciones.map((direccion: any) => ({
                        calle: direccion.calle,
                        ciudad: direccion.ciudad,
                        estado: direccion.estado,
                        codigo_postal: direccion.codigo_postal,
                        direccion_id: direccion.direccion_id,
                        numero_cliente: direccion.numero_cliente,
                        numero_medidor: direccion.numero_medidor
                    }))
                });
            }
        } else {
            reset({ // Limpiar todos los campos cuando se agrega un nuevo cliente

                nombre: '',
                apellido: '',
                rut: '',
                email: '',
                telefono: '',
                direcciones: [{calle: "", ciudad: "", estado: "", codigo_postal: "", numero_cliente:"", numero_medidor:""}]
            });
        }

        // Abrir el modal después de un pequeño retraso para asegurar que los valores se hayan establecido
        setTimeout(() => {
            setTitulo(id > 0 ? 'Editar Cliente' : 'Nuevo Cliente');
            setIsModalShow(true);
        }, 100);
    };




    const closeModal = () => {
        reset();
        clearErrors();
        setIsEdit(false);
        setIsModalShow(false);
    };

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {
            const payload = {
                nombre: data.nombre,
                apellido: data.apellido,
                rut: data.rut,
                email: data.email,
                telefono: data.telefono,
                direcciones: data.direcciones
            };

            Swal.fire({
                title: 'Esperando...',
                text: 'Guardando el cliente!',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const {status} = await axios({
                method: isEdit ? 'put' : 'post',
                url: `${URL_BASE}/clientes${isEdit ? '/' + data.cliente_id : ''}`,
                data: payload,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            Swal.close();

            if (status === 200) {
                Swal.fire(
                    'Guardado!',
                    'El cliente ha sido guardado.',
                    'success'
                );
                reset();
                closeModal();
                await fetchData();
            }
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrió un error al guardar el cliente!',
            });
        }
    };


    const openImportModal = () => {
        setIsImportModalShow(true);
    };

    const closeImportModal = () => {
        setIsImportModalShow(false);
    };

    const handleImportSubmit = async (event: any) => {
        event.preventDefault();
        const file = event.target.file.files[0];

        const formData = new FormData();
        formData.append('file', file);

        try {
            Swal.fire({
                title: 'Esperando...',
                text: 'Importando datos!',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const {status} = await axios.post(URL_BASE + '/importClients', formData);

            Swal.close();

            if (status === 200) {
                Swal.fire(
                    'Importado!',
                    'Los datos han sido importados.',
                    'success'
                );
                closeImportModal();
                await fetchData();
            }

        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrió un error al importar los datos!',
            });
        }
    };

    const handleChangePage = async (page: number) => {
        const filters = {
            ...params,
            page: page,
        };
        setParams(filters);
       const {data , status } = await axios.get(URL_BASE + '/clientes?opcion=paginate&page=' + page)

        if (status === 200) {
            setHouses(data.data);
            setPaginate(data.links);
        }
    };

    const handleFilterChange = async (selectedOption: any) => {
        setSelectedClient(selectedOption);
        const filters = {
            ...params,
            filters: {
                cliente_id: selectedOption?.value || ''
            },
            page: 1,
        };
        setParams(filters);

        const url = URL_BASE + '/clientes?opcion=paginate&filtro[cliente_id]=' + selectedOption?.value;

        const {data, status} = await axios.get(url);

        if (status === 200) {
            setHouses(data.data);
            setPaginate(data.links);
        }

    };

    const resetFiltro = async () => {
        setSelectedClient(null);
        const filters = {
            ...params,
            filters: {},
            page: 1,
        };
        setParams(filters);
        await fetchData();
    }

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };


    return (
        <PrivateLayout>
            <div className="container mt-5">
                <Card
                    title="Clientes"
                    toolActions={() => (
                        <div className="btn-group">
                            <button onClick={() => newHouse()}
                                    className="btn btn-warning btn-sm">
                                <FontAwesomeIcon icon={faPlus}/> Nuevo
                            </button>

                            {/* <button onClick={openImportModal}
                                className="btn btn-success btn-sm">
                            <FontAwesomeIcon icon={faUpload}/> Importar
                        </button>*/}

                            <button onClick={toggleFilters}
                                    className="btn btn-info btn-sm">
                                <FontAwesomeIcon icon={faFilter}/> Filtros
                            </button>
                        </div>
                    )}
                >
                    {showFilters && (
                        <div className="row">
                            <div className="col-md-3">
                                <Select
                                    options={clientes.map(cliente => ({
                                        value: cliente.cliente_id,
                                        label: cliente.nombre + ' ' + cliente.apellido
                                    }))}
                                    value={selectedClient}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className="col-md-3">

                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={resetFiltro}
                                >
                                    Limpiar
                                </button>
                            </div>
                        </div>
                    )}
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div className="table-responsive">
                            {houses?.length ? (
                                <DataTable
                                    items={houses}
                                    columns={columnsClientes}
                                    idKey={'cliente_id'}
                                    isActions={true}
                                    // @ts-ignore
                                    renderActions={(id: number) => (
                                        <div className='btn-group'>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={() => newHouse(id)}
                                            >
                                                <FontAwesomeIcon icon={faEdit}/>
                                            </button>
                                            <button
                                                className="btn btn-sm btn-danger"
                                                onClick={() => deleteHouses(id)}
                                            >
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </button>
                                        </div>
                                    )}
                                    onSortChange={(columnKey, direction) => {
                                        // Create a filters object with the new orderPor and order values
                                        const filters = {
                                            orderPor: columnKey,
                                            order: direction,
                                        };

                                        const url = URL_BASE + '/clientes?opcion=paginate&orderPor=' + columnKey + '&order=' + direction;
                                        axios.get(url)
                                            .then(response => {
                                                setHouses(response.data.data);
                                                setPaginate(response.data.links);
                                            });

                                    }}

                                />
                            ) : null}
                        </div>
                        <div className="row">
                        {paginate ? (
                            <Pagination
                                links={paginate}
                                onChange={(page) => handleChangePage(page)}
                            />
                        ) : null}
                    </div>
                    </div>
                </Card>
            </div>

            <Modal
                show={isModalShow}
                handleClose={closeModal}
                title={titulo}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">RUT</label>
                            <input
                                type="text"
                                className="form-control"
                                {...register("rut", {required: true})}
                            />
                            {errors.rut && <span className="text-danger">Este campo es obligatorio</span>}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Nombre</label>
                            <input
                                type="text"
                                className="form-control"
                                {...register("nombre", {required: true})}
                            />
                            {errors.nombre && <span className="text-danger">Este campo es obligatorio</span>}
                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="form-label">Apellido</label>
                            <input
                                type="text"
                                className="form-control"
                                {...register("apellido", {required: true})}
                            />
                            {errors.apellido && <span className="text-danger">Este campo es obligatorio</span>}
                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="form-label">Correo electrónico</label>
                            <input
                                type="email"
                                className="form-control"
                                {...register("email", {required: true})}
                            />
                            {errors.email && <span className="text-danger">Este campo es obligatorio</span>}
                        </div>

                        <div className="col-md-6 mb-3">
                            <label className="form-label">Teléfono</label>
                            <input
                                type="text"
                                className="form-control"
                                {...register("telefono", {required: true})}
                            />
                            {errors.telefono && <span className="text-danger">Este campo es obligatorio</span>}
                        </div>

                        <div className="col-md-12 mb-3">
                            <label className="form-label">Direcciones</label>
                            <div className="border-bottom mb-3"></div>
                            {fields.map((field, index) => (
                                <div key={field.id} className="mb-3">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Calle</label>
                                            <input
                                                type="text"
                                                placeholder="Calle"
                                                {...register(`direcciones.${index}.calle`, {required: true})}
                                                className="form-control mb-1"
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Ciudad</label>
                                            <input
                                                type="text"
                                                placeholder="Ciudad"
                                                {...register(`direcciones.${index}.ciudad`, {required: true})}
                                                className="form-control mb-1"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Provincia</label>
                                            <input
                                                type="text"
                                                placeholder="Estado"
                                                {...register(`direcciones.${index}.estado`, {required: true})}
                                                className="form-control mb-1"
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Codigo Postal</label>
                                            <input
                                                type="text"
                                                placeholder="Código Postal"
                                                {...register(`direcciones.${index}.codigo_postal`, {required: true})}
                                                className="form-control mb-1"
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Numero cliente</label>
                                            <input
                                                type="text"
                                                placeholder="Código Cliente"
                                                {...register(`direcciones.${index}.numero_cliente`, {required: true})}
                                                className="form-control mb-1"
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Numero de medidor</label>
                                            <input
                                                type="text"
                                                placeholder="Número Medidor"
                                                {...register(`direcciones.${index}.numero_medidor`, {required: true})}
                                                className="form-control mb-1"
                                            />
                                        </div>
                                    </div>

                                    <div className="border-bottom mb-3"></div>
                                    {index >= 1 && (
                                        <button
                                            type="button"
                                            className="mt-5 btn btn-danger btn-sm"
                                            onClick={() => remove(index)}
                                        >
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </button>
                                    )}
                                </div>
                            ))}
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() => append({calle: "", ciudad: "", estado: "", codigo_postal: "", numero_cliente:"", numero_medidor:""})}
                            >
                                <FontAwesomeIcon icon={faPlus}/>
                            </button>
                        </div>

                        {isEdit && (
                            <input type="hidden" {...register('id')} />
                        )}
                    </div>

                    <div className="d-flex justify-content-end">
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >Guardar
                        </button>
                    </div>
                </form>
            </Modal>


            <Modal
                show={isImportModalShow}
                handleClose={closeImportModal}
                title="Importar Datos"
            >
                <form onSubmit={handleImportSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Archivo Excel</label>
                        <input
                            type="file"
                            className="form-control"
                            name="file"
                            accept=".xlsx, .xls"
                            required
                        />
                    </div>

                    <div className="d-flex justify-content-end">
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >Importar
                        </button>
                    </div>
                </form>
            </Modal>
        </PrivateLayout>
    );
}




import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from "../slice/LoginSlice";

// Suponiendo que tienes un slice llamado loginSlice

const rootReducer = combineReducers({
    login: loginReducer,
    // Agrega otros reducers aquí
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

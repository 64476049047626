import {FC, useEffect, useState} from "react";
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt , faTrash} from '@fortawesome/free-solid-svg-icons'
// @ts-ignore
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



interface TableProps {
    data: any;
    columns: any;
    title?: string;
    search?: boolean;
    pagination?: boolean;
    isImage?: boolean;
    urlImage?: string;
    actionDelete?: any;
    actionNew?: any;
    actionEdit?: any;
    isAction?: boolean;
    colores?: any;
}

export const Table: FC<TableProps> = ({
                                          data,
                                          columns,
                                          title,
                                          search,
                                          pagination,
                                          actionDelete,
                                          actionEdit,
                                          actionNew,
                                            isAction=true,
                                            colores = []
                                      }) => {

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => {
            if (!data || !columns) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Data loading error',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                setIsDataLoaded(false);
                setIsLoading(false);
            } else {
                setIsDataLoaded(true);
                setIsLoading(false);
            }
        }, 2000); // 2000 milisegundos = 2 segundos

        return () => clearTimeout(timer); // Limpia el temporizador si el componente se desmonta antes de que se agote el tiempo
    }, [data, columns]);


    //funcion para filtrar los colores de los badges
    const getBadgeColor = (value: string) => {
        const color = colores.find((item: any) => item.status === value);
        return color ? color.color : '';
    }

    const translations = {
        'enabled': 'Activado',
        'disabled': 'Desactivado'
    };

    const translateStatus = (status: string) => {
        // @ts-ignore
        return translations[status] || status;
    };

    const handleImageClick = (src: string) => {
        Swal.fire({
            imageUrl: src,
            imageAlt: 'Custom image',
            showCloseButton: true, // Muestra el botón de cerrar
            showConfirmButton: false,
            width: 'auto', // Ancho automático según la imagen
        });
    }


    return (
        <div className="table-responsive">
            <table className="table table-bordered align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                <thead>
                <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                    {columns.map((item: any,index:number) => (
                        <th key={index}>{item.label}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {isLoading ? (
                    <tr><td>Cargando Datos...</td></tr>
                ) : (
                    data.map((item: any) => (
                        <tr className="odd" key={item.id}>
                            {columns.map((column: any, index:number) => (
                                <td key={index}>
                                    {column.isImage ? (
                                        <LazyLoadImage
                                            effect="blur"
                                            src={`${column.urlImage}${item[column.key]}`}
                                            width="200"
                                            className="img-thumbnail cursor-pointer"
                                            onClick={() => handleImageClick(`${column.urlImage}${item[column.key]}`)}
                                        />
                                    ) : (
                                        <span className={column.shouldHaveBadge ? `badge badge-${getBadgeColor(item[column.key])}` : ""}>{translateStatus(item[column.key])}</span>
                                    )}
                                </td>
                            ))}
                            {isAction && (
                                <td>
                                    <div className="btn-group">
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => actionEdit(item.id)}
                                        >
                                            <FontAwesomeIcon icon={faPencilAlt}/>
                                        </button>
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => actionDelete(item.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </button>
                                    </div>

                                </td>

                            )}
                        </tr>
                    ))
                )}
                </tbody>
            </table>
        </div>
    )
}
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import axios from "axios";
import { URL_BASE } from "../../config/config";
import { Card } from "../../components/ui/Card";
import { Modal } from "../../components/ui/Modal";
import Swal from "sweetalert2";
import { PrivateLayout } from "../../layouts/PrivateLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faFilter, faDownload } from "@fortawesome/free-solid-svg-icons";
import { IFormInput } from "./Formulario";
import Formulario from "./Formulario";
import Table from "./Table";
import Filtros from "./Filtros";

export const Tarifas = () => {
    const methods = useForm<IFormInput>();
    const { reset, setValue, handleSubmit, formState: { errors } } = methods;
    const [tarifas, setTarifas] = useState([]);
    const [isModalShow, setIsModalShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [titulo, setTitulo] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const { data } = await axios.get(`${URL_BASE}/tarifas`);
            setTarifas(data);
        } catch (error) {
            console.error("Error fetching tariffs data:", error);
        }
    }

    const newTarifa = (id: string | number = 0) => {
        if (id > 0) {
            setIsEdit(true);
            const tarifaEdit = tarifas.find((tarifa) => tarifa.tarifa_id === Number(id));
            if (tarifaEdit) {
                setValue('tarifa_id', tarifaEdit.tarifa_id);
                setValue('tarifa_corte', tarifaEdit.tarifa_corte);
                setValue('tarifa_reposicion', tarifaEdit.tarifa_reposicion);
                setValue('cargo_fijo', tarifaEdit.cargo_fijo);
                setValue('valor', tarifaEdit.valor);
                setValue('mes', tarifaEdit.mes);
                setValue('year', tarifaEdit.year);
            }
        } else {
            reset();
        }
        setTitulo(id > 0 ? 'Editar Tarifa' : 'Nueva Tarifa');
        setIsModalShow(true);
    }

    const closeModal = () => {
        reset();
        setIsEdit(false);
        setIsModalShow(false);
    }

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {
            Swal.fire({
                title: 'Esperando...',
                text: 'Guardando la tarifa!',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const response = isEdit ?
                await axios.put(`${URL_BASE}/tarifas/${data.tarifa_id}`, data) :
                await axios.post(`${URL_BASE}/tarifas`, data);

            Swal.close();

            if (response.status === 201 || response.status === 200) {
                Swal.fire(
                    'Guardado!',
                    'La tarifa ha sido guardada.',
                    'success'
                );
                reset();
                closeModal();
                fetchData();
            }
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrió un error al guardar la tarifa!',
            });
        }
    }

    return (
        <PrivateLayout>
            <div className="container mt-5">
                <Card
                    title="Tarifas"
                    toolActions={() => (
                        <div className="btn-group">
                            <button onClick={() => newTarifa()} className="btn btn-warning btn-sm">
                                <FontAwesomeIcon icon={faPlus} /> Nueva
                            </button>
                        </div>
                    )}
                >
                    <Table
                        tarifas={tarifas}
                        newTarifa={newTarifa}
                    />
                </Card>
            </div>

            <Modal show={isModalShow} handleClose={closeModal} title={titulo}>
                <FormProvider {...methods}>
                    <Formulario
                        isEdit={isEdit}
                        onSubmit={handleSubmit(onSubmit)}
                        errors={errors}
                    />
                </FormProvider>
            </Modal>
        </PrivateLayout>
    );
}

import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {MainMenu} from '../data/MainMenu';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

export const Menu = () => {
    const location = useLocation();

    return (<>


            <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu"
                 data-kt-menu="true"
                 data-kt-menu-expand="false">
                    <div className="menu-item pt-5">
                        <div className="menu-content">
                            <span className="menu-heading fw-bold text-uppercase fs-7">Consumos</span>
                        </div>
                    </div>
                {MainMenu.map((menuItem) => {
                    // @ts-ignore
                    const Icon = Icons[menuItem.icon];
                    return (

                        <div key={menuItem.id} data-kt-menu-trigger="click"
                             className={`menu-item here show menu-accordion ${location.pathname === menuItem.url ? 'active' : ''}`}>
                            <div className="menu-item">
                                <Link className={`menu-link ${location.pathname === menuItem.url ? 'active' : ''}`}
                                      to={menuItem.url}>
                                <span className="menu-icon">
                                    <FontAwesomeIcon icon={Icon}/>
                                </span>
                                    <span className="menu-title">{menuItem.name}</span>
                                </Link>
                            </div>
                        </div>

                    )
                })}
            </div>
        </>
    )
}
export const MainMenu = [
    {
        id: 1,
        name: 'Inicio',
        url: '/dashboard',
        icon: 'faTachometerAlt', // New icon
    },
    {
        id: 2,
        name: 'Clientes',
        url: '/clientes',
        icon: 'faUserFriends', // New icon
    },
    {
        id: 3,
        name: 'Consumo Mensual',
        url: '/consumo-mensual-luz',
        icon: 'faChartLine', // New icon
    },
    {
        id: 4,
        name: 'Boleta Mensual',
        url: '/boleta-mensual-luz',
        icon: 'faFileInvoice', // New icon
    },
    {
        id: 5,
        name: 'Pagos',
        url: '/pago-mensual-luz',
        icon: 'faMoneyCheckAlt', // New icon
    },
    {
        id: 6,
        name: 'Tarifas',
        url: '/tarifas-mensual',
        icon: 'faTags', // New icon
    },
    {
        id: 7,
        name: 'Usuarios',
        url: '/usuarios',
        icon: 'faUsersCog', // New icon
    },
];

export const mainMenuClientes = [
    {
        id: 1,
        name: 'Inicio',
        url: '/dashboard',
        icon: 'faHome', // Change this to the name of the new icon
    },
    {
        id: 2,
        name: 'Casas',
        url: '/casas',
        icon: 'faBuilding', // Change this to the name of the new icon
    },
    {
        id: 3,
        name: 'Consumo Mensual',
        url: '/consumo-mensual-luz',
        icon: 'faBolt', // Change this to the name of the new icon
    },
];


import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import {persistor, RootState} from './store/store'
import { PersistGate } from 'redux-persist/integration/react';
import {useSelector} from "react-redux";  // Importar PersistGate

function App() {
    const token = useSelector((state: RootState) => state.login.token);

    if (token) {
        document.body.id = 'kt_app_body';
        document.body.className = 'app-default';

        document.body.setAttribute('data-kt-app-layout', 'dark-sidebar');
        document.body.setAttribute('data-kt-app-header-fixed', 'true');
        document.body.setAttribute('data-kt-app-sidebar-enabled', 'true');
        document.body.setAttribute('data-kt-app-sidebar-fixed', 'true');
        document.body.setAttribute('data-kt-app-sidebar-hoverable', 'true');
        document.body.setAttribute('data-kt-app-sidebar-push-header', 'true');
        document.body.setAttribute('data-kt-app-sidebar-push-toolbar', 'true');
        document.body.setAttribute('data-kt-app-sidebar-push-footer', 'true');
        document.body.setAttribute('data-kt-app-toolbar-enabled', 'true');
    }

  return (
    <>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <AppRoutes />
            </Router>
        </PersistGate>
    </>
  );
}

export default App;

// @ts-nocheck
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

interface IFormInput {
    house_id: number;
    mes: number;
    year: number;
}

interface FormularioProps {
    onSubmit: (data: IFormInput) => void;
    errors: any;
}

const Formulario: React.FC<FormularioProps> = ({
                                                   onSubmit,
                                                   errors
                                               }) => {
    const { register, setValue } = useFormContext();

    useEffect(() => {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        setValue("month", currentMonth);
        setValue("year", currentYear);
    }, [setValue]);

    return (
        <form onSubmit={onSubmit}>
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Mes</label>
                <select
                    className="form-select form-select-solid"
                    {...register("month", { required: true })}
                >
                    <option value="">Selecciona un mes</option>
                    <option value="1">Enero</option>
                    <option value="2">Febrero</option>
                    <option value="3">Marzo</option>
                    <option value="4">Abril</option>
                    <option value="5">Mayo</option>
                    <option value="6">Junio</option>
                    <option value="7">Julio</option>
                    <option value="8">Agosto</option>
                    <option value="9">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                </select>
                {errors.mes && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Año</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("year", { required: true })}
                />
                {errors.year && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">Generar</button>
            </div>
        </form>
    );
};

export default Formulario;

import {useEffect, useState} from "react";
import {useForm, SubmitHandler} from "react-hook-form";
import axios from "axios";
import {URL_WEB_BASE, URL_BASE} from "../config/config";
import {Card} from "../components/ui/Card";
import {Table} from "../components/ui/Table";
import {Modal} from "../components/ui/Modal";
import Swal from "sweetalert2";
import {columnsUsuarios} from "../data/Datos"; // Make sure to define columnsUsuarios in Datos.tsx
import {useCRUD} from "../hooks/useCRUD";
import {PrivateLayout} from "../layouts/PrivateLayout";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IFormInput {
    id?: number;
    name: string;
    last_name: string;
    email: string;
    rut: string;
    password?: string;
    roles: string;
}

export const Usuarios = () => {
    const {register, handleSubmit, reset, setValue, formState: {errors}, clearErrors} = useForm<IFormInput>();
    const [usuarios, setUsuarios] = useState([]);
    const [isModalShow, setIsModalShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [titulo, setTitulo] = useState('');
    const roles = [
        {key: 'admin', name: 'Admin'},
        {key: 'capturador', name: 'User'}
    ];

    const {
        data,
        fetchData,
        deleteData,
        addOrUpdateData
    } = useCRUD({
        endpoint: URL_BASE + '/usuarios'
    })

    useEffect(() => {
        setUsuarios(data)
    }, [data]);

    const deleteUsuario = (id: number) => {
        Swal.fire({
            title: '¿Estas seguro de eliminar este usuario?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Si, eliminar!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const {status} = await axios.delete(URL_BASE + '/usuarios/' + id);
                if (status === 200) {
                    Swal.fire(
                        'Eliminado!',
                        'El usuario ha sido eliminado.',
                        'success'
                    )
                    fetchData();
                }
            }
        })
    }

    const editUsuario = (id: number) => {
        const usuarioEdit = usuarios.find((usuario: any) => usuario.id === id);
        // @ts-ignore
        setValue('id', usuarioEdit?.id)
        // @ts-ignore
        setValue('name', usuarioEdit.name)
        // @ts-ignore
        setValue('last_name', usuarioEdit.last_name)
        // @ts-ignore
        setValue('email', usuarioEdit.email)
        // @ts-ignore
        setValue('rut', usuarioEdit.rut)
        // @ts-ignore
        setValue('roles', usuarioEdit.roles)
        setIsEdit(true);
        setIsModalShow(true);
    }

    const newUsuario = (id = 0) => {
        if (id > 0) {
            editUsuario(id);
        } else {
            setIsEdit(false);
            setIsModalShow(true);
        }
        setTitulo(id > 0 ? 'Editar Usuario' : 'Nuevo Usuario')
    }

    const closeModal = () => {
        reset();
        clearErrors();
        setIsEdit(false);
        setIsModalShow(false);
    }

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {
            // @ts-ignore
            const formData = new FormData();
            // @ts-ignore
            formData.append('name', data.name);
            // @ts-ignore
            formData.append('last_name', data.last_name);
            // @ts-ignore
            formData.append('email', data.email);
            // @ts-ignore
            formData.append('rut', data.rut);
            // @ts-ignore
            formData.append('roles', data.roles);
            if (data.password) {
                // @ts-ignore
                formData.append('password', data.password);
            }

            Swal.fire({
                title: 'Esperando...',
                text: 'Guardando el usuario',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            const {status} = await axios.post(URL_BASE + `/usuarios${isEdit ? '/' + data.id : ''}`, formData);

            Swal.close();

            if (status === 201) {
                Swal.fire(
                    'Guardado!',
                    'El usuario ha sido guardado.',
                    'success'
                )
                reset();
                closeModal();
                await fetchData();
            }
        } catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Ocurrio un error al guardar el usuario!',
            })
        }
    }

    return (
        <PrivateLayout>
            <div className="container mt-5">
                <Card title={"Usuarios"} toolActions={
                    () => (
                        <div className="btn-group">
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => newUsuario()}
                            >
                                <FontAwesomeIcon icon={faPlus}/> Nuevo Usuario
                            </button>
                        </div>
                    )

                }>
                    <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <Table
                            data={usuarios}
                            columns={columnsUsuarios}
                            actionDelete={deleteUsuario}
                            actionEdit={newUsuario}
                        />
                    </div>
                </Card>
            </div>

            <Modal
                show={isModalShow}
                handleClose={closeModal}
                title={titulo}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-10 fv-row fv-plugins-icon-container">
                        <label className="form-label">Nombre</label>
                        <input
                            type="text"
                            className="form-control form-control-solid"
                            {...register("name")}
                        />
                        {isEdit && (
                            <input type="hidden" {...register('id')}/>
                        )}
                    </div>

                    <div className="mb-10 fv-row fv-plugins-icon-container">
                        <label className="form-label">Apellido</label>
                        <input
                            type="text"
                            className="form-control form-control-solid"
                            {...register("last_name")}
                        />
                    </div>

                    <div className="mb-10 fv-row fv-plugins-icon-container">
                        <label className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control form-control-solid"
                            {...register("email")}
                        />
                    </div>

                    <div className="mb-10 fv-row fv-plugins-icon-container">
                        <label className="form-label">RUT</label>
                        <input
                            type="text"
                            className="form-control form-control-solid"
                            {...register("rut")}
                        />
                    </div>

                    <div className="mb-10 fv-row fv-plugins-icon-container">
                        <label className="form-label">Contraseña</label>
                        <input
                            type="password"
                            className="form-control form-control-solid"
                            {...register("password")}
                        />
                    </div>

                    <div className="mb-10 fv-row fv-plugins-icon-container">
                        <label className="form-label">Rol</label>
                        <select
                            className="form-control form-control-solid"
                            {...register("roles")}
                        >
                            {roles.map(role => (
                                <option key={role.key} value={role.key}>
                                    {role.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="d-flex justify-content-end">
                        <button
                            type="submit"
                            className="btn btn-primary"
                        >Guardar
                        </button>
                    </div>

                </form>
            </Modal>
        </PrivateLayout>
    )
}

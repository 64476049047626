import {URL_WEB_BASE} from "../config/config";
import {Column} from "../types/Columns";
/*
interface Column {
    id: number;
    label: string;
    key?: string;
    isImage?: boolean;
    urlImage?: string;
    shouldHaveBadge?: boolean;
    badgeType?: string;
}
*/


export const columnsUsuarios: Column[] = [
    {
        id: 1,
        label: 'Id',
        key: 'id',
        isVisible: true
    },
    {
        id: 2,
        label: 'Nombre',
        key: 'name',
        isVisible: true
    },
    {
        id: 3,
        label: 'Correo',
        key: 'email',
        isVisible: true
    },
    {
        id: 4,
        label: 'Acciones',
    }
]


export const columnsClientes: Column[] = [
    {
        id: 1,
        label: 'Nº',
        key: 'cliente_id',
        isVisible: true
    },
    {
        id: 2,
        label: 'RUT',
        key: 'rut',
        isVisible: true
    },
    {
        id: 3,
        label: 'Nombre Completo',
        key: 'nombre_completo',
        isVisible: true
    },

];
export const columnsmonthlyConsumption: Column[] = [
    {
        id: 8,
        label: 'Nombre Cliente',
        key: 'nombre_cliente'
    },
    {
        id: 2,
        label: 'Dirección',
        key: 'direccion.calle'
    },

    {
        id: 5,
        label: 'Consumo Anterior',
        key: 'consumo_anterior'
    },
    {
        id: 6,
        label: 'Consumo Actual',
        key: 'consumo_actual'
    },
    {
        id: 7,
        label: 'Consumo del Mes (kWh)',
        key: 'consumo_mes_kwh'
    },
    {
        id: 4,
        label: 'Fecha lectura',
        key: 'fecha_lectura_formateada'
    },
    {
        id: 3,
        label: 'Mes',
        key: 'mes_lectura'
    },

];



export const columnsInvoices: Column[] = [
    {
        id: 1,
        label: 'Id',
        key: 'INVOICE_ID'
    },
    {
        id: 2,
        label: 'Dirección',
        key: 'ADDRESS'
    },

    {
        id: 4,
        label: 'Consumo Actual',
        key: 'CONSUMPTION'
    },
    {
        id:5,
        label: 'Mes',
        key: 'MONTH'
    },
    {
        id:6,
        label: 'Año',
        key: 'YEAR'
    },
    {
        id:7,
        label: 'Monto',
        key: 'AMOUNT_DUE'
    },

    {
        id:9,
        label: 'Fecha de Vencimiento',
        key: 'DUE_DATE'
    },
    {
        id:10,
        label: 'Estado',
        key: 'STATUS'
    }
 ]
export const columnsTarifas: Column[] = [
    {
        id: 1,
        label: 'Id',
        key: 'tarifa_id'
    },
    //mes
    {
        id: 2,
        label: 'Mes',
        key: 'nombre_mes'
        //mes name functuion


    },
    //year
    {
        id: 3,
        label: 'Año',
        key: 'year'
    },
    //tipo_consumo
    {
        id: 3,
        label: 'Tipo Tarifa',
        key: 'tipo_tarifa'
    },

]
export const columnsFacturas = [
    {
        id: 1,
        label: 'Id',
        key: 'factura_id'
    },
    {
        id: 2,
        label: 'Nombre cliente',
        key: 'nombre_completo'
    },
    {
        id: 2,
        label: 'Dirección',
        key: 'direccion.calle'
    },
    {
        id: 3,
        label: 'Mes',
        key: 'mes'
    },
    {
        id: 4,
        label: 'Año',
        key: 'year'
    },
    {
        id: 5,
        label: 'Monto',
        key: 'monto_total_string'
    },
    {
        id: 6,
        label: 'Fecha de emisión',
        key: 'fecha_emision'
    },
    {
        id: 7,
        label: 'Fecha de Vencimiento',
        key: 'fecha_vencimiento'
    },
    {
        id: 8,
        label: 'Estado',
        key: 'estado',
        shouldHaveBadge: true,
    }
];

export const columnsPagos = [
    {
        id: 1,
        label: 'Id',
        key: 'pago_id'
    },
    {
        id: 2,
        label: 'RUT',
        key: 'rut_cliente'
    },
    {
        id: 3,
        label: 'Nombre Completo',
        key: 'nombre_completo'
    },
    {
        id: 4,
        label: 'Monto Pagado',
        key: 'monto_pagado_string'
    },
    {
        id: 5,
        label: 'Fecha de Pago',
        key: 'fecha_pago'
    },
    {
        id: 6,
        label: 'Estado',
        key: 'estado_pago'
    }
]
//import {UserFile} from "./UserFile";
//import pluma from "../Assets/images/pluma.png";
import Swal from "sweetalert2";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../slice/LoginSlice";

export const Header = () => {

   // const user = JSON.parse(localStorage.getItem('user') || '{}');
    const user = useSelector((state: any) => state.login.user)
    const dispatch = useDispatch();


    const handleLogout = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Sí, salir!'
        }).then((result) => {
            if (result.isConfirmed) {
                // @ts-ignore
                Swal.fire({
                    title: 'Saliendo...',
                    timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    },
                }).then(() => {
                    dispatch(logout());
                })
            }
        })
    };


    return (
        <div id="kt_app_header" className="app-header">
            <div className="app-container container-fluid d-flex  justify-content-between" id="kt_app_header_container">
                <div className="d-flex  justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
                <div className="app-header-menu app-header-mobile-drawer align-items-stretch"
                     data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="{default: 'append', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}">

                </div>

                    <div className="app-navbar flex-shrink-0">
                        <div className="app-navbar-item  ms-1 ms-md-3" style={{marginLeft:'25px'}}>

                            <div
                                className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
                                id="kt_activities_toggle">
                                <button className="btn btn-danger" onClick={() => handleLogout()}>Salir</button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}
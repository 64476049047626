// @ts-nocheck
// @ts-nocheck
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { DataTable } from "../../components/ui/DataTable";
import {columnsTarifas} from "../../data/Datos";

interface TableProps {
    tarifas: Array<any>;
    newTarifa: (id: string | number) => void;
}



const Table: React.FC<TableProps> = ({ tarifas, newTarifa }) => {
    return (
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
                {tarifas?.length ? (
                    <DataTable
                        items={tarifas}
                        columns={columnsTarifas}
                        idKey={'tarifa_id'}
                        isActions={true}
                        renderActions={(id) => (
                            <div className='btn-group'>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() => newTarifa(Number(id))} // Convertir a número
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </button>
                            </div>
                        )}
                    />
                ) : (
                    <p style={{ marginTop: '30px', fontSize: '16px' }}>No hay tarifas disponibles.</p>
                )}
            </div>
        </div>
    );
};

export default Table;

import React from "react";
import imgLateral from '../assets/images/banner.jpg'


const backgroundImageStyle = {
    backgroundImage: 'url('+imgLateral+')',
    width: '60%',
};
export const Aside = ()=>{
    return (
        <div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={backgroundImageStyle}>

        </div>
    )
}
// @ts-nocheck
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export interface IFormInput {
    factura_id: number;
    fecha_pago: string;
    monto_pagado: number;
    voucher_transferencia: string;
    estado_pago: string;
}

interface FormularioProps {
    onSubmit: (data: IFormInput) => void;
    errors: any;
}

const Formulario: React.FC<FormularioProps> = ({
                                                   onSubmit,
                                                   errors
                                               }) => {
    const { register, setValue } = useFormContext();

    useEffect(() => {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        setValue("month", currentMonth);
        setValue("year", currentYear);
    }, [setValue]);

    return (
        <form onSubmit={onSubmit}>
            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">ID de Factura</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("factura_id", { required: true })}
                />
                {errors.factura_id && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Fecha de Pago</label>
                <input
                    type="date"
                    className="form-control form-control-solid"
                    {...register("fecha_pago", { required: true })}
                />
                {errors.fecha_pago && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Monto Pagado</label>
                <input
                    type="number"
                    className="form-control form-control-solid"
                    {...register("monto_pagado", { required: true })}
                />
                {errors.monto_pagado && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Voucher de Transferencia</label>
                <input
                    type="text"
                    className="form-control form-control-solid"
                    {...register("voucher_transferencia", { required: true })}
                />
                {errors.voucher_transferencia && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="mb-10 fv-row fv-plugins-icon-container">
                <label className="form-label">Estado del Pago</label>
                <select
                    className="form-control form-control-solid"
                    {...register("estado_pago", { required: true })}
                >
                    <option value="pendiente">Pendiente</option>
                    <option value="pagado">Pagado</option>
                </select>
                {errors.estado_pago && <span className="text-danger">Este campo es obligatorio</span>}
            </div>

            <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">Generar</button>
            </div>
        </form>
    );
};

export default Formulario;

